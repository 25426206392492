(() => {
  angular.module('app').component('commonInpatientTicketsDetailHospitalChargesComponent', {
    templateUrl: require('./hospitalCharges.component.html'),
    controller: HospitalChargesController,
    controllerAs: 'vm',
    bindings: {
      ticket: '<',
      doctors: '<',
      isEdit: '<',
      isSaved: '<',
      categories: '<',
      benefits: '<',
      fixedLimitBalance: '<',
      isCreate: '<',
      currencyCode: '<',
      isClaim: '<'
    },
  });

  HospitalChargesController.$inject = [
    'abp.services.app.commonLookup',
    'Hms.ServiceRequests.ServiceRequestType',
  ];

  function HospitalChargesController(
    commonLookupSvc,
    enumServiceRequestType,
  ) {
    const vm = this;

    vm.hasTxtFileSupport = abp.setting.getBoolean('Hms.Feature.TxtFileSupport');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );
    vm.formattedCategories = [];
    const defaultTicketItem = {
      category: '',
      isManualEdit: 0,
      ticketItems: [
        {
          subTreatments: [],
          procedures: [],
        },
      ],
    };
    vm.isInpatientTicketV2 = vm.hasInsuranceEnhancementModule && vm.ticket.version === 'V2';

    vm.enums = {
      serviceRequestType: enumServiceRequestType
    };

    vm.$onChanges = onChanges;
    vm.addCategory = addCategory;
    vm.addProcedures = addProcedures;
    vm.clearProcedures = clearProcedures;
    vm.deleteItem = deleteItem;
    vm.updateProcedures = updateProcedures;
    vm.getCategoryName = getCategoryName;
    vm.getDoctorName = getDoctorName;
    vm.calculateTotal = calculateTotal;
    vm.calculateAmount = calculateAmount;
    vm.getAmount = getAmount;

    vm.manualEdit = manualEdit;
    vm.cancelManualEdit = cancelManualEdit;
    vm.saveManualEdit = saveManualEdit;
    vm.updateCategory = updateCategory;
    vm.updateSubCategory = updateSubCategory;
    vm.fulfillV2ViewCondition = fulfillV2ViewCondition;
    vm.getSubtotalAmount = getSubtotalAmount;
    vm.getTotalCoveredAmount = getTotalCoveredAmount;
    vm.getTotalUncoveredAmount = getTotalUncoveredAmount;

    init();

    function init() {
      // Get master billing codes for hospital

      if (vm.hasTxtFileSupport) getMasterBillingCode();

      vm.ticket.hospitalCharges = _.chain(vm.ticket.hospitalCharges)
        .groupBy('category')
        .map((value, key) => ({
          category: key,
          ticketItems: value,
          name: _.first(value).categoryName,
          isManualEdit: 0,
        }))
        .value();

      refreshOnTicketChange();

      if (vm.hasInsuranceEnhancementModule) formatCategories();
      if (vm.isInpatientTicketV2) getTicketItems();
    }

    function refreshOnTicketChange() {
      if (!vm.ticket || !vm.ticket.hospitalCharges) return;
      if (vm.ticket.hospitalCharges.length === 0) {
        if (vm.isEdit) addCategory();
      } else {
        _.each(vm.ticket.hospitalCharges, (x) => {
          _.each(x.ticketItems, (ticketItem) => {
            const ti = ticketItem;
            if (ti.amount !== undefined && ti.uncoveredAmount !== undefined)
              ti.totalCharges = ti.amount + ti.uncoveredAmount;

            ti.isOverride = 0;
          });
        });
      }
    }

    function onChanges(changes) {
      if (changes.ticket && changes.ticket.currentValue) {
        refreshOnTicketChange();
      }

      if (changes.isSaved && changes.isSaved.currentValue) {
        tidyProcedures();
      }

      if (changes.isEdit && changes.isEdit.currentValue) {
        if (vm.ticket && vm.ticket.hospitalCharges.length < 1) {
          addCategory();
        }
      }
    }

    function addCategory(type) {
      const categoryItem = vm.hasInsuranceEnhancementModule
        ? JSON.parse(JSON.stringify(defaultTicketItem))
        : {
            category: type || null,
            ticketItems: [
              {
                category: type || null,
                categoryName: type ? getCategoryName(type) : null,
                isOverride: 0,
              },
            ],
            isManualEdit: 0,
          };
      vm.ticket.hospitalCharges.push(categoryItem);
    }

    function addProcedures(record) {
      if (vm.hasInsuranceEnhancementModule) {
        const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));

        // Format new ticket item.

        newTicketItem.category = record.category;

        // Add sub treatments items.

        if (record.category) {
          const category = _.filter(
            vm.formattedCategories,
            (f) => f.mainTreatmentCategoryName === record.category
          );

          newTicketItem.subTreatments = category[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );
          newTicketItem.section = category[0].sectionType;
        }

        // Add new ticket item.

        record.ticketItems.push(newTicketItem);
      } else {
        const r = record;
        if (typeof r.category === 'undefined' || r.category === null) return;

        if (!r.ticketItems) {
          r.ticketItems = [];
        }
        const defaultMasterBillingCode = r.procedures ? r.procedures[0].code : null;
        r.ticketItems.push({
          category: r.category,
          categoryName: getCategoryName(r.category),
          isOverride: 0,
          masterBillingCode: defaultMasterBillingCode,
        });
      }
    }

    function clearProcedures(category) {
      const c = category;
      c.ticketItems = [];
      addProcedures(c);
    }

    function tidyProcedures() {
      vm.ticket.hospitalCharges = _.filter(vm.ticket.hospitalCharges, (item) =>
        _.filter(item.ticketItems, (k) => !_.isNil(k.amount))
      );
    }

    function calculateTotal(index, item) {
      const i = item;
      let uncoveredAmount = 0;
      let amount = 0;
      if (i[index].amount > 0) {
        amount = i[index].amount;
      }
      if (i[index].uncoveredAmount > 0) {
        uncoveredAmount = i[index].uncoveredAmount;
      }
      const totalCharges = amount + uncoveredAmount;
      i[index].totalCharges = totalCharges;
    }

    function calculateAmount(index, item, parentIndex) {
      const i = item;
      vm.ticket.hospitalCharges[parentIndex].ticketItems[index].isOverride = 0;

      let category = '';

      if (i[index].category) category = i[index].category;

      i[index].hasExceeded = false;
      i[index].isUncovered = false;

      // Validate amount.

      validateTotalAmountByCategory(category);
    }

    function validateTotalAmountByCategory(category) {
      const categories = [
        'OperatingTheatre',
        'HospitalSuppliesAndServices',
        'Ambulance Fee',
        'Medical Report',
      ];

      let totalAmount = 0;
      let fixedLimitTotalAmount = 0;

      _.each(vm.ticket.hospitalCharges, (x) => {
        _.each(x.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          let balanceAmount = 0;
          let totalCharges = 0;
          let coveredTotalCharges = 0;
          let uncoveredTotalCharges = 0;
          let limitAmount = 0;
          let isFixedLimit = false;
          let isAsCharged = false;

          if (ti.totalCharges > 0) totalCharges = ti.totalCharges;

          if (vm.hasInsuranceEnhancementModule && !categories.includes(ti.category)) {
            ti.amount = totalCharges;
            ti.uncoveredAmount = 0;
            return;
          }

          // Categories naming for their respective old category names.

          let oldCategory;
          switch (ti.category) {
            case 'Ambulance Fee':
            case 'AmbulanceFee':
              oldCategory = 'AmbulanceFee';
              break;
            case 'Medical Report':
            case 'MedicalReport':
              oldCategory = 'MedicalReport';
              break;
            case 'OperatingTheatre':
              oldCategory = 'OperatingTheatre';
              break;
            case 'HospitalSuppliesAndServices':
              oldCategory = 'HospitalSuppliesAndServices';
              break;
            default:
              break;
          }

          const benefitByLimit = _.first(
            _.filter(vm.benefits, (b) => b.category === oldCategory && b.type === 0)
          );

          if (ti.isOverride === 1) return;

          let isCovered;

          switch (ti.category) {
            case 'OperatingTheatre':
            case 'HospitalSuppliesAndServices':
              isFixedLimit = (benefitByLimit && benefitByLimit.isFixedLimit) || false;
              limitAmount =
                isFixedLimit && checkHasDoctorProcedures()
                  ? vm.fixedLimitBalance
                  : (benefitByLimit && benefitByLimit.limitAmount) || 0;

              if (isFixedLimit)
                balanceAmount =
                  fixedLimitTotalAmount < limitAmount ? limitAmount - fixedLimitTotalAmount : 0;
              else balanceAmount = totalAmount < limitAmount ? limitAmount - totalAmount : 0;

              isAsCharged = (benefitByLimit && benefitByLimit.isAsCharged) || false;
              if (!isAsCharged) {
                if (totalCharges > balanceAmount) {
                  coveredTotalCharges = balanceAmount;
                  uncoveredTotalCharges = totalCharges - balanceAmount;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;

                  if (totalCharges) ti.hasExceeded = true;
                } else {
                  coveredTotalCharges = totalCharges;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;
                }
              } else {
                coveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;
              }

              break;

            case 'Ambulance Fee':
            case 'AmbulanceFee':
              isCovered = !_.isNil(benefitByLimit);
              if (isCovered) {
                limitAmount = (benefitByLimit && benefitByLimit.limitAmount) || 0;
                balanceAmount = totalAmount < limitAmount ? limitAmount - totalAmount : 0;

                isAsCharged = (benefitByLimit && benefitByLimit.isAsCharged) || false;
                if (!isAsCharged) {
                  if (totalCharges > balanceAmount) {
                    coveredTotalCharges = balanceAmount;
                    uncoveredTotalCharges = totalCharges - balanceAmount;

                    ti.amount = coveredTotalCharges;
                    ti.uncoveredAmount = uncoveredTotalCharges;

                    if (totalCharges) ti.hasExceeded = true;
                  } else {
                    coveredTotalCharges = totalCharges;

                    ti.amount = coveredTotalCharges;
                    ti.uncoveredAmount = uncoveredTotalCharges;
                  }
                } else {
                  coveredTotalCharges = totalCharges;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;
                }
              } else {
                uncoveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;

                if (totalCharges) ti.isUncovered = true;
              }

              break;

            case 'Medical Report':
            case 'MedicalReport':
              limitAmount = (benefitByLimit && benefitByLimit.limitAmount) || 0;
              balanceAmount = totalAmount < limitAmount ? limitAmount - totalAmount : 0;

              isAsCharged = (benefitByLimit && benefitByLimit.isAsCharged) || false;
              if (!isAsCharged) {
                if (totalCharges > balanceAmount) {
                  coveredTotalCharges = balanceAmount;
                  uncoveredTotalCharges = totalCharges - balanceAmount;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;

                  if (totalCharges) ti.hasExceeded = true;
                } else {
                  coveredTotalCharges = totalCharges;

                  ti.amount = coveredTotalCharges;
                  ti.uncoveredAmount = uncoveredTotalCharges;
                }
              } else {
                coveredTotalCharges = totalCharges;

                ti.amount = coveredTotalCharges;
                ti.uncoveredAmount = uncoveredTotalCharges;
              }

              break;

            default:
              break;
          }

          if (isFixedLimit) fixedLimitTotalAmount += totalCharges;
          else if (ti.category === category) totalAmount += totalCharges;
        });
      });
    }

    function checkHasDoctorProcedures() {
      let hasRecords = false;

      _.each(vm.ticket.doctorProcedures, (x) => {
        if (!_.isNil(x.category)) {
          hasRecords = _.some(x.ticketItems, (p) => !_.isNil(p.category));
        }

        if (hasRecords) return false;
        return true;
      });

      return hasRecords;
    }

    function getAmount() {
      let amount = 0;
      let uncoveredAmount = 0;

      _.each(vm.ticket.hospitalCharges, (charge) => {
        const x = charge;
        x.showExceededMessage = false;
        x.showUncoveredMessage = false;

        _.each(x.ticketItems, (p) => {
          if (p.amount) amount += p.amount;

          if (p.uncoveredAmount) uncoveredAmount += p.uncoveredAmount;

          if (p.hasExceeded) x.showExceededMessage = true;

          if (p.isUncovered) x.showUncoveredMessage = true;
        });
      });

      return {
        subtotalAmount: App.roundAmount(amount + uncoveredAmount),
        totalCoveredAmount: App.roundAmount(amount),
        totalUncoveredAmount: App.roundAmount(uncoveredAmount),
      };
    }

    function updateProcedures(index, item) {
      if (vm.hasTxtFileSupport && vm.isEdit) {
        getProcedureItems(item);
      }

      if (typeof item.category === 'undefined' || item.category === null) {
        if (vm.ticket.hospitalCharges.length > 1) {
          vm.ticket.hospitalCharges.splice(index, 1);
        } else {
          vm.ticket.hospitalCharges = [];
          addCategory();
        }
      } else if (item.ticketItems) {
        _.each(item.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          ti.category = item.category;
          ti.categoryName = getCategoryName(item.category);

          if (vm.hasTxtFileSupport)
            ti.masterBillingCode =
              item.category !== 'HospitalSuppliesAndServices' ? item.procedures[0].code : null;
        });
      }
    }

    function deleteItem(index, item) {
      const i = item;
      if (i.ticketItems.length > 1) {
        i.ticketItems.splice(index, 1);
      } else {
        i.ticketItems = [];
        addProcedures(i);
      }
    }

    function getDoctorName(id) {
      const idToMatch = parseInt(id, 10);
      if (vm.doctors) {
        const selectedDoctor = _.find(vm.doctors, (d) => d.id === idToMatch);

        if (selectedDoctor) {
          return selectedDoctor.name;
        }
      }
      return null;
    }

    function manualEdit(index) {
      vm.tempPlaceHolder = angular.copy(vm.ticket.hospitalCharges[index]);
      vm.ticket.hospitalCharges[index].isManualEdit = 1;
    }

    function cancelManualEdit(index) {
      vm.ticket.hospitalCharges[index] = angular.copy(vm.tempPlaceHolder);
      vm.ticket.hospitalCharges[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;
    }

    function saveManualEdit(index) {
      vm.ticket.hospitalCharges[index].isManualEdit = 0;
      vm.tempPlaceHolder = null;

      _.each(vm.ticket.hospitalCharges[index].ticketItems, (ticketItem) => {
        const p = ticketItem;
        p.isOverride = 1;
      });
    }

    function getCategoryName(category) {
      const selectedCategory = _.find(vm.categories, { id: category });
      return selectedCategory ? selectedCategory.displayName : null;
    }

    function getMasterBillingCode() {
      vm.loading += 1;

      const codeList = [
        'HospitalSuppliesAndServices',
        'AmbulanceFee',
        'MedicalReport',
        'OperatingTheatre',
      ];
      commonLookupSvc
        .getMasterBillingCodesByCodeList(codeList)
        .success((data) => {
          vm.codes = data;

          // Set description

          if (vm.ticket.hospitalCharges.length > 0) {
            _.each(vm.ticket.hospitalCharges, (procedure) => {
              getProcedureItems(procedure);
              _.each(procedure.ticketItems, (ticketItem) => {
                const ti = ticketItem;
                const procedureItem = _.find(vm.codes, (x) => x.code === ti.masterBillingCode);

                if (!procedureItem) {
                  ti.unmatchedMasterBillingCode = ti.masterBillingCode;
                  ti.masterBillingCode = null;
                }

                if (procedureItem) {
                  ti.description = procedureItem.description;
                }
              });
            });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getProcedureItems(item) {
      const i = item;
      i.procedures = _.filter(vm.codes, (code) => {
        const x = code;
        if (
          x.parentCode === 'HospitalSuppliesAndServices' ||
          x.code === 'HospitalSuppliesAndServices'
        )
          return x.parentCode === i.category;
        return x.code === i.category;
      }).sort((a, b) => (a.description > b.description ? 1 : -1));

      if (i.procedures.length === 1) {
        _.each(i.ticketItems, (ticketItem) => {
          const ti = ticketItem;
          ti.masterBillingCode = i.procedures[0].code;
        });
      }
    }

    function formatCategories() {
      // Get main categories.

      _.forEach(vm.categories, (x) => {
        if (
          vm.formattedCategories
            .map((e) => e.mainTreatmentCategoryName)
            .indexOf(x.mainTreatmentCategoryName) < 0
        ) {
          vm.formattedCategories.push({
            mainTreatmentCategoryName: x.mainTreatmentCategoryName,
            sectionType: x.sectionType,
            subTreatmentCategories: [],
          });
        }
      });

      // Get sub categories.

      _.forEach(vm.formattedCategories, (x) => {
        const subTreatments = _.filter(
          vm.categories,
          (c) => c.mainTreatmentCategoryName === x.mainTreatmentCategoryName
        );

        _.forEach(subTreatments, (s) => {
          if (
            x.subTreatmentCategories
              .map((e) => e.subTreatmentItemName)
              .indexOf(s.subTreatmentItemName) < 0
          ) {
            x.subTreatmentCategories.push({
              subTreatmentItemName: !s.subTreatmentItemName
                ? x.mainTreatmentCategoryName
                : s.subTreatmentItemName,
              procedures: [],
            });
          }
        });
      });

      // Get procedures.

      _.forEach(vm.formattedCategories, (x) => {
        _.forEach(x.subTreatmentCategories, (s) => {
          const procedures = _.filter(
            vm.categories,
            (c) => c.subTreatmentItemName === s.subTreatmentItemName
          );

          _.forEach(procedures, (p) => {
            if (p.procedures) {
              s.procedures.push(p.procedures);
            }
          });
        });
      });
    }

    function updateCategory(index, item) {
      if (item.category) {
        // Clear sub categories & procedures.

        _.forEach(item.ticketItems, (x) => {
          x.subTreatment = '';
          x.procedure = '';
          x.uncoveredProcedure = '';
          x.subTreatments = [];
          x.procedures = [];
        });

        // Update each ticketItem in item.

        const category = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === item.category
        );

        _.forEach(item.ticketItems, (x) => {
          x.category = item.category;
          x.section = category[0].sectionType;

          // Add sub treatments items.

          x.subTreatments = category[0].subTreatmentCategories.map((s) => s.subTreatmentItemName);
        });
      } else if (index > 0) {
        vm.ticket.emergencyOutpatientTreatments.splice(index, 1);
      } else {
        clearProcedures(item);
      }
    }

    function updateSubCategory(index, item, ticketItem) {
      // Clear procedures.

      ticketItem.procedure = '';
      ticketItem.uncoveredProcedure = '';
      ticketItem.procedures = [];

      // Add procedure items.

      const category = _.filter(
        vm.formattedCategories,
        (f) => f.mainTreatmentCategoryName === item.category
      );

      const subTreatment = _.filter(
        category[0].subTreatmentCategories,
        (s) => s.subTreatmentItemName === ticketItem.subTreatment
      );

      ticketItem.procedures = subTreatment[0].procedures;
    }

    function getTicketItems() {
      const ticketItems = _.filter(
        vm.ticket.ticketItems,
        (item) => item.category === 'Hospital charges'
      );

      formatTicketItems(ticketItems);
    }

    function formatTicketItems(items) {
      const mainTreatments = [];

      // Get main treatments.

      _.forEach(items, (item) => {
        if (_.indexOf(mainTreatments, item.categoryName) < 0) {
          mainTreatments.push(item.categoryName);
        }
      });

      // Get ticket items per main treatment.

      _.forEach(mainTreatments, (main) => {
        const ticketItems = _.filter(items, (x) => x.categoryName === main);
        const newCategory = JSON.parse(JSON.stringify(defaultTicketItem));

        newCategory.category = main;
        newCategory.ticketItems = [];

        // Get sub categories and procedures.

        const mainTreatment = _.filter(
          vm.formattedCategories,
          (f) => f.mainTreatmentCategoryName === main
        );

        _.forEach(ticketItems, (x) => {
          const newTicketItem = JSON.parse(JSON.stringify(defaultTicketItem.ticketItems[0]));
          const subTreatment = _.filter(
            mainTreatment[0].subTreatmentCategories,
            (s) => s.subTreatmentItemName === x.subCategory
          );

          newTicketItem.subTreatments = mainTreatment[0].subTreatmentCategories.map(
            (s) => s.subTreatmentItemName
          );

          newTicketItem.procedures = subTreatment[0].procedures;

          newTicketItem.amount = x.amount;
          newTicketItem.uncoveredAmount = x.uncoveredAmount;
          newTicketItem.section = x.category;
          newTicketItem.category = x.categoryName;
          newTicketItem.subTreatment = x.subCategory;
          newTicketItem.procedure = x.procedure;
          newTicketItem.uncoveredProcedure = x.uncoveredProcedure;
          newTicketItem.remark = x.remark;
          newTicketItem.uncoveredRemark = x.uncoveredRemark;
          newTicketItem.totalCharges = x.amount + x.uncoveredAmount;

          newCategory.ticketItems.push(newTicketItem);
        });

        vm.ticket.hospitalCharges.push(newCategory);
      });
    }

    // Show V2 UI during create or edit when condition fulfilled.

    function fulfillV2ViewCondition() {
      const ticketVersion = !vm.isClaim ? vm.ticket.version : vm.ticket.ticketVersion;

      const isCreate = vm.isClaim ?
        vm.ticket.requestType !== vm.enums.serviceRequestType.Adjustment.name
        : vm.isCreate;

      return (vm.hasInsuranceEnhancementModule && (isCreate || ticketVersion === 'V2'));
    }

    function getSubtotalAmount() {
      return getAmount().subtotalAmount;
    }

    function getTotalCoveredAmount() {
      return getAmount().totalCoveredAmount;
    }

    function getTotalUncoveredAmount() {
      return getAmount().totalUncoveredAmount;
    }
  }
})();
