(() => {
  const componentId = 'hostInsurersCreateEditMyInvoisSettings';

  angular.module('app').component(componentId, {
    require: {
      container: '^hostInsurersCreateEditContainer',
    },
    bindings: {
      data: '<',
      isCreate: '<',
      insurerIdentityServerTenantId: '<',
    },
    templateUrl: require('./myInvoisSettings.component.html'),
    controller: InsurerMyInvoisSettingsController,
    controllerAs: 'vm',
  });

  InsurerMyInvoisSettingsController.$inject = [
    '$scope',
    'Hms.Finance.MalaysiaTaxpayerIdType',
    'abp.services.finance.malaysiaTaxInformation',
    'abp.services.app.malaysiaTaxInformation',
  ];

  function InsurerMyInvoisSettingsController(
    $scope,
    enumMalaysiaTaxpayerIdType,
    financeMalaysiaTaxInformationSvc,
    portalMalaysiaTaxInformationSvc
  ) {
    const vm = this;

    vm.save = save;
    vm.loading = 0;
    vm.saving = 0;
    vm.rollingNumbers = [];
    vm.isEditing = false;
    vm.permissions = {
      edit: abp.auth.isGranted('Host.NewInsurers.EditFinance'),
      create: abp.auth.isGranted('Host.NewInsurers.Create'),
    };

    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.isDirty = isDirty;
    vm.setPristine = setPristine;

    vm.$onInit = () => {
      vm.container.addWidget(this);
    };

    // Functions utilised in container file.

    // Force validation on this form and proceed with attempting to validate TIN.
    // Returns true regardless of invalid parameters or failed validation.

    function validateForm() {
      App.touchFormErrors($scope.insurerCreateEditMyInvoisSettingsForm);
      if ($scope.insurerCreateEditMyInvoisSettingsForm.$invalid) {
        abp.notify.error(App.localize('TaxInformationIncorrect'));
        return true;
      }
      
      const insurerMYTaxInformation = {
        tenantIdentityServiceId: vm.insurerIdentityServerTenantId,
        subTenantId: null,
        idType: enumMalaysiaTaxpayerIdType.BRN.id,
        businessRegistrationNumber: vm.data.businessRegistrationNumber,
        taxIdentificationNumber: vm.data.taxIdentificationNumber,
        sstRegistrationNumber: vm.data.sstRegistrationNumber,
        supportContactName: vm.data.supportContactName,
        supportContactEmail: vm.data.supportContactEmail,
        supportContactNumber: vm.data.supportContactNumber,
      };

      const input = [];
      input.push(insurerMYTaxInformation);

      vm.loading += 1;
      financeMalaysiaTaxInformationSvc
        .bulkValidateTin({
          taxInformations: input,
        })
        .success((message) => {
          if (message.output) abp.message.error(message.output);
          else {
            portalMalaysiaTaxInformationSvc
              .createUpdateTaxInformation({ subTenants: input })
              .success(() => {
                abp.notify.info(App.localize('TaxInformationSuccessfullySaved'));
              });
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });

      return true;
    }

    // Generate the payload from this form to send to InsurerAppService API.

    function getPayload() {
      return {
        insurerMyInvoisSettings: vm.data,
      };
    }

    // Ask container to save this widget.

    function save() {
      vm.container.saveWidget(vm);
    }

    function isDirty() {
      return $scope.insurerCreateEditMyInvoisSettingsForm.$dirty;
    }

    function setPristine() {
      $scope.insurerCreateEditMyInvoisSettingsForm.$setPristine();
    }
  }
})();
