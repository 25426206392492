import swal from 'sweetalert';

(() => {
  angular
    .module('app')
    .controller('host.views.claims.inpatientClaims.fglProcessing', FGLProcessingController);

  FGLProcessingController.$inject = [
    '$scope',
    '$state',
    '$uibModal',
    '$stateParams',
    'abp.services.app.hostServiceRequest',
    'abp.services.app.commonLookup',
    'moment',
    'appConstants',
    'Hms.Services.ServiceType',
    'Hms.Doctors.DoctorType',
  ];

  function FGLProcessingController(
    $scope,
    $state,
    $modal,
    $stateParams,
    hostServiceRequestSvc,
    commonLookupSvc,
    moment,
    appConstants,
    enumServiceType,
    enumDoctorType
  ) {
    const vm = this;

    vm.loading = 0;
    vm.isHost = App.isHost();
    vm.letterNumber = $stateParams.letterNumber;
    vm.timeZoneId = $stateParams.timeZoneId;
    vm.currencyCode = abp.setting.get('Hms.General.CurrencyCode');
    vm.requestInfo = {};
    vm.hospitalizationBenefits = [];
    vm.isOnHold = false;
    vm.isFormFilled = false;
    vm.isInsuranceBenefitGroup = false;

    vm.coPay = {
      isCoInsurance: false,
      isPercentage: false,
      value: 0,
    };

    vm.requestDetailsTrigger = 0;
    vm.checkSaveTrigger = checkSaveTrigger;
    vm.checkEditMode = checkEditMode;
    vm.openApprovalModal = openApprovalModal;
    vm.openRejectionModal = openRejectionModal;
    vm.openDelegationModal = openDelegationModal;
    vm.openRecommendationModal = openRecommendationModal;
    vm.openAssigneeModal = openAssigneeModal;
    vm.openEscalationModal = openEscalationModal;
    vm.saveDraft = saveDraft;
    vm.preview = preview;
    vm.query = query;
    vm.checkFormFilled = checkFormFilled;
    vm.requestAmount = null;
    vm.totalCoveredAmount = null;

    let delegateUsers = [];
    let recommendUsers = [];

    vm.requestType = 'Final';
    vm.requestStatus = 'Pending approval';

    // Check if inpatient benefit type is maternity

    vm.isMaternity = false;
    vm.isTallied = true;
    vm.isFinalBillTallied = true;

    vm.coPay = {
      isRoomAndBoardExceedEntitlement: false,
      isCoInsurance: false,
      isPercentage: false,
      coPayExclusions: [],
      value: 0,
    };

    vm.roomAndBoardCharges = 0;
    vm.hasSobEnhancement = abp.setting.getBoolean('Hms.Feature.SobEnhancement');
    vm.hasInsuranceEnhancementModule = abp.setting.getBoolean(
      'Hms.Feature.InsuranceEnhancementModule'
    );

    vm.enums = {
      serviceType: enumServiceType,
      doctorType: enumDoctorType,
    };

    function checkEditMode(isEdit) {
      vm.isEdit = isEdit;
    }

    function checkSaveTrigger() {
      vm.requestDetailsTrigger += 1;
    }

    function getRequestInput(isApproving, approverRemarks) {
      let roomItems = [];
      let procedureItems = [];
      const otherItems = [];
      let doctorProcedureItems = [];
      let hospitalChargesItems = [];
      let emergencyOutpatientTreatments = [];
      let miscellaneousTreatments = [];
      let ticketItems = [];

      _.each(vm.requestInfo.request.rooms, (x) => {
        _.each(x.ticketItems, (y) => {
          y.startDate = y.roomChargeDateRangeModel.startDate
            ? moment(y.roomChargeDateRangeModel.startDate).utc()
            : null;
          y.endDate = y.roomChargeDateRangeModel.endDate
            ? moment(y.roomChargeDateRangeModel.endDate).utc()
            : null;

          // Skip add records if category is null and approving the request.

          if (!isApproving) {
            y.category = x.category;
          }

          if (typeof y.category !== 'undefined' && y.category !== null) {
            roomItems.push(y);
          }
        });
      });

      if (vm.hasInsuranceEnhancementModule && !vm.isMaternity) {
        roomItems = formatTicketItems(vm.requestInfo.request.rooms, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.roomChargeDateRangeModel.startDate
            ? moment(x.roomChargeDateRangeModel.startDate).utc()
            : null,
          endDate: x.roomChargeDateRangeModel.endDate
            ? moment(x.roomChargeDateRangeModel.endDate).utc()
            : null,
          dailyCharges: x.dailyCharges,
          lengthOfStay: x.lengthOfStay,
        }));

        procedureItems = formatTicketItems(vm.requestInfo.request.procedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
          totalVisit: 1,
          visit: x.visit,
        }));

        doctorProcedureItems = formatTicketItems(vm.requestInfo.request.doctorProcedures, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
          startDate: x.visitDate ? moment(x.visitDate).utc() : null,
          endDate: x.visitDate ? moment(x.visitDate).utc() : null,
          doctorId: x.doctorId,
        }));

        hospitalChargesItems = formatTicketItems(vm.requestInfo.request.hospitalCharges, (x) => ({
          amount: x.amount,
          uncoveredAmount: x.uncoveredAmount,
          category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
          categoryName: x.category,
          subCategory: x.subTreatment,
          procedure: x.procedure,
          uncoveredProcedure: x.uncoveredProcedure,
          remark: x.remark,
          uncoveredRemark: x.uncoveredRemark,
        }));

        emergencyOutpatientTreatments = formatTicketItems(
          vm.requestInfo.request.emergencyOutpatientTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
            categoryName: x.category,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          })
        );

        miscellaneousTreatments = formatTicketItems(
          vm.requestInfo.request.miscellaneousTreatments,
          (x) => ({
            amount: x.amount,
            uncoveredAmount: x.uncoveredAmount,
            category: vm.hasInsuranceEnhancementModule ? x.category : x.section,
            categoryName: x.mainTreatment,
            subCategory: x.subTreatment,
            procedure: x.procedure,
            uncoveredProcedure: x.uncoveredProcedure,
            remark: x.remark,
            uncoveredRemark: x.uncoveredRemark,
          })
        );

        ticketItems = _.unionWith(
          roomItems,
          procedureItems,
          doctorProcedureItems,
          hospitalChargesItems,
          emergencyOutpatientTreatments,
          miscellaneousTreatments
        );
      } else if (vm.hasSobEnhancement && !vm.isMaternity) {
        _.each(vm.requestInfo.request.procedures, (x) => {
          _.each(x.ticketItems, (y) => {
            y.startDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.endDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.doctorType = x.doctorType;
            y.doctorId = x.doctorId;

            // Total visit is always 1.

            if (!y.totalVisit || y.totalVisit !== 1) y.totalVisit = 1;

            if (
              (typeof y.doctorId !== 'undefined' &&
                y.doctorId !== null &&
                y.doctorType === vm.enums.doctorType.Doctor.id &&
                vm.requestInfo.request.serviceType === vm.enums.serviceType.Hospitalization.id) ||
              (y.doctorType === vm.enums.doctorType.Anaesthetist.id &&
                vm.requestInfo.request.serviceType === vm.enums.serviceType.Hospitalization.id) ||
              (typeof y.category !== 'undefined' &&
                y.category !== null &&
                vm.requestInfo.request.serviceType === vm.enums.serviceType.Maternity.id) ||
              !isApproving
            ) {
              procedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.doctorProcedures, (x) => {
          _.each(x.ticketItems, (y) => {
            y.startDate = y.visitDate ? moment(y.visitDate).utc() : null;
            y.endDate = y.visitDate ? moment(y.visitDate).utc() : null;

            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              doctorProcedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.hospitalCharges, (x) => {
          _.each(x.ticketItems, (y) => {
            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              hospitalChargesItems.push(y);
            }
          });
        });

        ticketItems = _.unionWith(
          roomItems,
          procedureItems,
          doctorProcedureItems,
          hospitalChargesItems
        );
      } else {
        _.each(vm.requestInfo.request.procedures, (x) => {
          _.each(x.ticketItems, (y) => {
            y.startDate = y.procedureChargeDateRangeModel
              ? moment(y.procedureChargeDateRangeModel.startDate).utc()
              : null;
            y.endDate = y.procedureChargeDateRangeModel
              ? moment(y.procedureChargeDateRangeModel.endDate).utc()
              : null;

            // Skip add records if category is null and approving the request.

            if (!isApproving) {
              y.category = x.category;
            }

            if ((typeof y.category !== 'undefined' && y.category !== null) || !isApproving) {
              procedureItems.push(y);
            }
          });
        });

        _.each(vm.requestInfo.request.others, (x) => {
          if ((typeof x.category !== 'undefined' && x.category !== null) || !isApproving) {
            otherItems.push(x);
          }
        });

        ticketItems = _.unionWith(roomItems, procedureItems, otherItems);
      }

      const cleanedDiagnoses = _.map(vm.requestInfo.request.diagnoses, (d) =>
        // Free-text entry has null diagnosis code.

        ({
          code: _.isNumber(d.code) ? null : d.code,
          description: d.description,
          diagnosisDate: d.diagnosisDate,
        })
      );

      return {
        id: vm.requestInfo.request.id,
        remarks: approverRemarks,
        isApprove: isApproving,
        currentAllowance: vm.employeeAllowance,
        request: {
          finalBillAmount: vm.requestInfo.request.finalBillAmount,
          admissionDate: vm.requestInfo.request.admissionDate,
          dischargeDate: vm.requestInfo.request.dischargeDate,
          doctorIds: vm.requestInfo.request.doctorIds,
          diagnoses: cleanedDiagnoses,
          documents: vm.requestInfo.request.documents,
          medicalCertificate: vm.requestInfo.request.medicalCertificate,
          ticketItems,
          hospitalizationDays: vm.requestInfo.request.hospitalizationDays,
          hospitalizationLeaveDates: vm.requestInfo.request.hospitalizationLeaveDates,
        },
      };
    }

    function formatTicketItems(treatments, format) {
      const items = [];

      _.each(treatments, (x) => {
        _.each(x.ticketItems, (y) => {
          if (!y.mainTreatment && !y.category && !y.doctorType) return;

          const ticketItem = angular.copy(y);
          items.push(format(ticketItem));
        });
      });

      return items;
    }

    function processRequest(isApproving, approverRemarks) {
      if (!isApproving) {
        $scope.$broadcast('validateProcessingInput');
        if (!vm.isFormFilled) {
          abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
          return;
        }
      }

      const input = getRequestInput(isApproving, approverRemarks);
      vm.loading += 1;
      hostServiceRequestSvc
        .processFglRequest(input)
        .success(() => {
          if (isApproving) {
            abp.notify.success(App.localize('Approved'));
            navigateToListing();
          } else {
            abp.notify.info(App.localize('SuccessfullySaved'));
          }
        })
        .error((data) => {
          /* Error code 1 == Insufficient allowance */
          if (data.code === 1) {
            /* Insufficient employee balance for request amount */
            swal(
              {
                title: App.localize('InvalidAmount'),
                text: `${App.localize('InsufficientEmployeeBalanceNarrative')}\n${App.localize(
                  'RefreshThePageAndEditTheAmount'
                )}`,
                confirmButtonColor: '#1AB394',
                confirmButtonText: App.localize('RefreshNow'),
              },
              (isConfirm) => {
                if (isConfirm) {
                  window.location.reload();
                }
              }
            );
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function approve(remarks) {
      processRequest(true, remarks);
    }

    function reject(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .rejectGuaranteeLetterServiceRequest({
          id: vm.requestInfo.request.id,
          letterNumber: vm.letterNumber,
          rejectionReason: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('Rejected'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function delegate(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .delegateRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.requestInfo.request.id,
          letterNumber: vm.letterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestDelegated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function recommend(recipient, remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .recommendRequest({
          processorUserId: recipient.operatorUserId,
          serviceRequestId: vm.requestInfo.request.id,
          letterNumber: vm.letterNumber,
          emailAddress: recipient.operatorEmailAddress,
          remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('ServiceRequestRecommended'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function saveDraft() {
      processRequest(false, '');
    }

    function checkFormFilled(data) {
      vm.isFormFilled = data;
    }

    function openApprovalModal() {
      // Validation on input.

      $scope.$broadcast('validateProcessingInput');

      if (vm.isFormFilled) {
        if (!vm.isTallied) {
          vm.isFinalBillTallied = false;
          abp.message.error(App.localize('FinalBillNotTallied'));
          return;
        }
        vm.isFinalBillTallied = true;

        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
              controller: 'common.views.modals.remarksModal as vm',
              backdrop: 'static',
              resolve: {
                title() {
                  return App.localize('ApprovalConfirmation');
                },
                existingRemarks() {
                  return vm.requestInfo.request.escalationRemarks;
                },
                remarksMaxLength() {
                  return appConstants.serviceRequestMaxLength;
                },
              },
            })
            .result.then((remarks) => {
              approve(remarks);
            });
        } else {
          promptEditModeWarning();
        }
      } else {
        abp.message.error(App.localize('OneOrMoreInputValueIsInvalid'));
      }
    }

    function openRejectionModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('RejectionConfirmation');
              },
              existingRemarks() {
                return vm.requestInfo.request.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            reject(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function openDelegationModal() {
      getServiceRequestClaimProcessors(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return delegateUsers;
                },
                title() {
                  return App.localize('DelegationConfirmation');
                },
                message() {
                  return App.localize('DelegationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              delegate(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function openRecommendationModal() {
      getServiceRequestOverridingApprovers(() => {
        if (!vm.isEdit) {
          $modal
            .open({
              templateUrl: require('../../../../common/views/modals/notifyRecipient/notifyRecipient.modal.html'),
              controller: 'common.views.modals.notifyRecipientModal as vm',
              backdrop: 'static',
              resolve: {
                users() {
                  return recommendUsers;
                },
                title() {
                  return App.localize('RecommendationConfirmation');
                },
                message() {
                  return App.localize('RecommendationWarningMessage');
                },
              },
            })
            .result.then((result) => {
              recommend(result.recipient, result.remarks);
            });
        } else {
          promptEditModeWarning();
        }
      });
    }

    function openAssigneeModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../../common/views/modals/assigneeConfirmation/assigneeConfirmation.modal.html'),
            controller: 'common.views.modals.assigneeConfirmationModal as vm',
            backdrop: 'static',
            resolve: {
              users(users) {
                return users;
              },
              title() {
                return App.localize('AssigneeConfirmation');
              },
              assigneeName() {
                return 'Doctor A';
              },
            },
          })
          .result.then((recipients, remarks) => {
            delegate(recipients, remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function promptEditModeWarning() {
      abp.message.error(App.localize('SaveOrCancelChangesNarrative'));
    }

    function navigateToListing() {
      $state.go('host.inpatientClaims');
    }

    function getServiceRequestClaimProcessors(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestClaimProcessor()
        .success((data) => {
          // Filter away current user
          delegateUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function getServiceRequestOverridingApprovers(_callback) {
      vm.loading += 1;

      commonLookupSvc
        .getServiceRequestOverridingApprover()
        .success((data) => {
          // Filter away current user
          recommendUsers = _.remove(
            data.items,
            (n) => n.value.operatorUserId !== abp.session.userId
          );
        })
        .finally(() => {
          vm.loading -= 1;
          _callback();
        });
    }

    function preview() {
      window.open(vm.printUrl);
    }

    function query() {
      if (vm.hasUnresolvedQuery) {
        $state.go('inpatientGuaranteeLettersDetails', {
          letterNumber: vm.requestInfo.request.letterNumber,
          timeZoneId: vm.timeZoneId,
          tab: 3,
        });
      } else {
        $state.go('createQuery', {
          letterNumber: vm.requestInfo.request.letterNumber,
          timeZoneId: vm.timeZoneId,
        });
      }
    }

    function openEscalationModal() {
      if (!vm.isEdit) {
        $modal
          .open({
            templateUrl: require('../../../../common/views/modals/remarks/remarks.modal.html'),
            controller: 'common.views.modals.remarksModal as vm',
            backdrop: 'static',
            resolve: {
              title() {
                return App.localize('EscalationConfirmation');
              },
              existingRemarks() {
                return vm.requestInfo.request.escalationRemarks;
              },
              remarksMaxLength() {
                return appConstants.serviceRequestMaxLength;
              },
            },
          })
          .result.then((remarks) => {
            escalate(remarks);
          });
      } else {
        promptEditModeWarning();
      }
    }

    function escalate(remarks) {
      vm.loading += 1;
      hostServiceRequestSvc
        .escalateGuaranteeLetterRequest({
          id: vm.requestInfo.request.id,
          isResolve: true,
          escalationRemark: remarks,
        })
        .success(() => {
          abp.notify.success(App.localize('GlEscalated'));
          navigateToListing();
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    init();

    function init() {
      vm.loading += 1;
      hostServiceRequestSvc
        .getFglRequestDetails({
          id: vm.letterNumber,
        })
        .success((data) => {
          vm.isLoading = true;
          vm.requestInfo = data;
          vm.printUrl = data.printUrl;
          vm.isOnHold = data.request.isOnHold;
          vm.hasUnresolvedQuery = data.request.hasUnresolvedQuery;
          vm.isMaternity = data.request.serviceType === vm.enums.serviceType.Maternity.id;
          vm.claimNumber = $stateParams.claimNumber;
          vm.isInsuranceBenefitGroup = data.patient.isInsuranceBenefitGroup;
          vm.currencyCode = data.request.currencyCode;
          vm.countryCode = data.request.countryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }
  }
})();
