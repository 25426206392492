(() => {
  angular.module('app').component('corporateEditContainer', {
    templateUrl: require('./widget.html'),
    controller: CorporateEditContactController,
    controllerAs: 'vm',
    bindings: {},
  });

  CorporateEditContactController.$inject = [
    '$scope',
    '$stateParams',
    'abp.services.app.tenantSettings',
    'abp.services.app.commonLookup',
    'Hms.ProrateMode',
    'Abp.Configuration.Setting',
  ];

  function CorporateEditContactController(
    $scope,
    $stateParams,
    tenantSettingsSvc,
    commonLookupSvc,
    enumProrateMode,
    constsAbpConfig
  ) {
    const vm = this;
    const widgets = [];
    vm.constsAbpConfig = constsAbpConfig;
    vm.loading = 0;
    vm.saving = 0;
    vm.settings = null;
    vm.isCorporate = App.isCorporate();
    vm.isInpatient = App.isInpatient() && App.isHospital();
    vm.isClinic = App.isClinic();
    vm.isSpecialist = vm.isClinic && App.isSpecialist();
    vm.isPharmacy = vm.isClinic && App.isPharmacy();
    vm.hasSpecialist = vm.isCorporate && abp.features.isEnabled('Specialist.Enabled');
    vm.activeTab = $stateParams.tab || 0;
    vm.isOperatingHourSettingEnabled = abp.features.isEnabled('ClinicModule.OperatingHour');
    vm.isSelfManageGL = abp.features.isEnabled('Specialist.PreferSelfManageGl');
    vm.hasInpatient = abp.features.isEnabled('HasInpatientModule');
    vm.checkedAutoDeactivation = null;
    vm.hasInsurance = abp.features.isEnabled('HasInsurance');

    vm.canAutoDeactivateDependent = abp.features.isEnabled('CanAutoDeactivateDependent');

    vm.enums = {};
    vm.enums.prorateMode = enumProrateMode;

    // Function declarations

    vm.addWidget = addWidget;
    vm.saveAll = saveAll;

    $scope.$on('$viewContentLoaded', () => {
      App.initAjax();
    });

    function init() {
      getAllSettings();
      getTenantCountryCode();
    }

    // Register child widget to this container.
    // Expose to child widget to register themselves.

    function addWidget(widget) {
      if (widget) {
        widgets.push(widget);
      }
    }

    function saveAll() {
      let error = false;

      _.each(widgets, (w) => {
        w.validateForm(error);
        w.getPayload(vm.settings);
      });

      if (error) {
        abp.notify.error(App.localize('GeneralInvalidFormInputError'));
        return;
      }

      if (vm.checkedAutoDeactivation === 0)
        vm.settings.employeeManagement.globalDependentAutoDeactivationAge = 0;

      vm.saving += 1;
      tenantSettingsSvc
        .updateAllSettings(vm.settings)
        .success(() => {
          abp.notify.info(App.localize('CorporateSettingsSuccessfullySaved'));
        })
        .finally(() => {
          vm.saving -= 1;
        });
    }

    function getTenantCountryCode() {
      vm.loading += 1;
      commonLookupSvc
        .getTenantCountryCode()
        .success((countryCode) => {
          vm.countryCode = countryCode;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getAllSettings() {
      vm.loading += 1;
      tenantSettingsSvc
        .getAllSettings()
        .success((data) => {
          vm.settings = data;

          if (vm.settings.employeeManagement?.globalDependentAutoDeactivationAge === 0) {
            vm.checkedAutoDeactivation = 0;
          } else {
            vm.checkedAutoDeactivation = 1;
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    init();
  }
})();
