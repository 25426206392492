(() => {
  angular.module('app').component('revenueSubscriptionsDetailReportsComponent', {
    templateUrl: require('./reports.component.html'),
    controller: ReportsController,
    controllerAs: 'vm',
    bindings: {
      subscription: '<',
    },
  });

  ReportsController.$inject = ['abp.services.revenue.subscription'];

  function ReportsController(subscriptionSvc) {
    const vm = this;

    vm.reports = [];
    vm.loading = 0;
    vm.selectedTab = 1;

    vm.downloadReport = downloadReport;
    vm.tabSelected = tabSelected;

    init();

    function init() {
      getReports();
    }

    function getReports() {
      vm.loading += 1;
      subscriptionSvc
        .getReports({
          id: vm.subscription.id,
        })
        .success((data) => {
          vm.headcountReports = data.headcountReports;
          vm.movementReports = data.movementReports;
          vm.headcountChargesReports = data.headcountChargesReports;
          vm.movementChargesReports = data.movementChargesReports;
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function downloadReport(report) {
      window.open(report, '_blank');
    }

    function tabSelected(i) {
      vm.selectedTab = i;
    }
  }
})();
