(function () {
    var revenueModule = angular.module('revenue');

    // Agent

    revenueModule.factory('abp.services.revenue.agent', [
        '$http', function ($http) {
            return new function () {
                this.getAgentForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/getAgentForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.createOrUpdateAgent = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/createOrUpdateAgent',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.suspendAgent = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/suspendAgent',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.reinstateAgent = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/reinstateAgent',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.disableCommission = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/disableCommission',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.enableCommission = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/enableCommission',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getAgents = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/getAgents',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getNextAgentCode = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/getNextAgentCode',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.deleteAgent = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agent/deleteAgent',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Agent dashboard

    revenueModule.factory('abp.services.revenue.agentDashboard', [
        '$http', function ($http) {
            return new function () {
                this.getAgents = function (httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/agentDashboard/getAgents',
                        method: 'GET'
                    }, httpParams));
                };
            };
        }
    ]);

    // Common

    revenueModule.factory('abp.services.revenue.common', [
        '$http', function ($http) {
            return new function () {
                this.findAgents = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/common/findAgents',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.findCorporates = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/common/findCorporates',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getPaymentChannels = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/common/getPaymentChannels',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getCurrencyByCorporateId = function (input, httpParams) {
                    return $http(angular.extend({
                      url: __REVENUE_ENDPOINT__ + '/api/app/common/getCurrencyByCorporateId',
                      method: 'GET',
                      params: input
                    }, httpParams));
                };

                this.getCurrentActiveCountries = function (httpParams) {
                    return $http(angular.extend({
                      url: __REVENUE_ENDPOINT__ + '/api/app/common/getCurrentActiveCountries',
                      method: 'GET',
                    }, httpParams));
                };

                this.getAccountOwnersByCountryCode = function (input, httpParams) {
                    return $http(angular.extend({
                      url: __REVENUE_ENDPOINT__ + '/api/app/common/getAccountOwnersByCountryCode',
                      method: 'GET',
                      params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Corporate

    revenueModule.factory('abp.services.revenue.corporate', [
        '$http', function ($http) {
            return new function () {
                this.getCorporates = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/getCorporates',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getCorporate = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/getCorporate',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateCorporate = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/updateCorporate',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getCorporateAgentsForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/getCorporateAgentsForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateCorporateAgents = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/updateCorporateAgents',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubsidiaries = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/getSubsidiaries',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSalesAgreements = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporate/getSalesAgreements',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Corporate comment

    revenueModule.factory('abp.services.revenue.corporateComment', [
        '$http', function ($http) {
            return new function () {
                this.getComments = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateComment/getComments',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.addComment = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateComment/addComment',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.deleteComment = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateComment/deleteComment',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Corporate sales agreement

    revenueModule.factory('abp.services.revenue.corporateSalesAgreement', [
        '$http', function ($http) {
            return new function () {
                this.getCorporateSalesAgreementForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateSalesAgreement/getCorporateSalesAgreementForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.createOrUpdateCorporateSalesAgreement = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateSalesAgreement/createOrUpdateCorporateSalesAgreement',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.deleteCorporateSalesAgreement = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/corporateSalesAgreement/deleteCorporateSalesAgreement',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Insurance policy

    revenueModule.factory('abp.services.revenue.insurancePolicy', [
        '$http', function ($http) {
            return new function () {
                this.getInsurancePolicies = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/insurancePolicy/getInsurancePolicies',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getInsurancePolicyForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/insurancePolicy/getInsurancePolicyForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateInsurancePolicy = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/insurancePolicy/updateInsurancePolicy',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.createInsurancePolicy = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/insurancePolicy/createInsurancePolicy',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.deleteInsurancePolicy = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/insurancePolicy/deleteInsurancePolicy',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Settings

    revenueModule.factory('abp.services.revenue.settings', [
        '$http', function ($http) {
            return new function () {
                this.getSettings = function (httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/settings/getSettings',
                        method: 'GET'
                    }, httpParams));
                };

                this.updateSettings = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/settings/updateSettings',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSetting = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/settings/getSetting',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Subscription

    revenueModule.factory('abp.services.revenue.subscription', [
        '$http', function ($http) {
            return new function () {
                this.getSubscriptions = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptions',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getCreateSubscriptionOptions = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getCreateSubscriptionOptions',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSubscriptionAgentsForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptionAgentsForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSubscription = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscription',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateSubscriptionAgents = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionAgents',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.createSubscription = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/createSubscription',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.deleteSubscription = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/deleteSubscription',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };

                this.getBillingCycles = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getBillingCycles',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.downloadHeadcountReport = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/downloadHeadcountReport',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.downloadBill = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/downloadBill',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateSubscriptionName = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionName',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubscriptionScopeForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptionScopeForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateSubscriptionScope = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionScope',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.updateSubscriptionPeriod = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionPeriod',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubscriptionBillingForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptionBillingForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.updateSubscriptionBilling = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionBilling',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.updateSubscriptionCharging = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscriptionCharging',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubscriptionListing = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptionListing',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.createSubscriptions = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/createSubscriptions',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.updateSubscription = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/updateSubscription',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubscriptionById = function (input, httpParams){
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getSubscriptionById',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.cancelSubscription = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/cancelSubscription',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.scheduleTermination = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/scheduleTermination',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.cancelTermination = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/cancelTermination',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getTransactionHistoryBySubscriptionId = function (input, httpParams){
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getTransactionHistoryBySubscriptionId',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getFinanceEmailsByTransactionNumber = function (input, httpParams){
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getFinanceEmailsByTransactionNumber',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };
				
				this.getReports = function (input, httpParams){
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscription/getReports',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // Member movement
    // TODO: Obsolete not used anywhere.
    revenueModule.factory('abp.services.revenue.memberMovement', [
        '$http', function ($http) {
            return new function () {
                this.getReportsByCorporate = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/memberMovement/getReportsByCorporate',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };
            };
        }
    ]);

    // TODO: Retire subscription plan old API when subscription plan new API goes live.

    revenueModule.factory('abp.services.revenue.subscriptionPlan', [
        '$http', function ($http) {
            return new function () {
                // Old API.

                this.getSubscriptionPlanForEdit = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlanForEdit',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.createOrUpdateSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/createOrUpdateSubscriptionPlan',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.deactivateSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/deactivateSubscriptionPlan',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.activateSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/activateSubscriptionPlan',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.getSubscriptionPlans = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlans',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.deleteSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/deleteSubscriptionPlan',
                        method: 'DELETE',
                        params: input
                    }, httpParams));
                };

                // New API.

                this.getSubscriptionPlansListing = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlansListing',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSubscriptionPlansListingToExcel = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlansListingToExcel',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlan',
                        method: 'GET',
                        params: input
                    }, httpParams));
                };

                this.getSubscriptionPlansDropdown = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/getSubscriptionPlansDropdown',
                        method: 'GET',
                        params: input
                    }, httpParams));
                }

                this.createSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/createSubscriptionPlan',
                        method: 'POST',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.updateSubscriptionPlan = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/updateSubscriptionPlan',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };

                this.updateSubscriptionPlanPricing = function (input, httpParams) {
                    return $http(angular.extend({
                        url: __REVENUE_ENDPOINT__ + '/api/app/subscriptionPlan/updateSubscriptionPlanPricing',
                        method: 'PUT',
                        data: JSON.stringify(input)
                    }, httpParams));
                };
            };
        }
    ]);
})();
