import _ from 'lodash';

(() => {
  angular.module('app').component('editIrbmMyInvoisSettings', {
    templateUrl: require('./widget.html'),
    controller: EditIrbmMyInvoisSettingsController,
    controllerAs: 'vm',
    require: {
      container: '^corporateEditContainer',
    },
    bindings: {
      data: '<',
      isClinic: '<',
      countryCode: '<'
    }
  });

  EditIrbmMyInvoisSettingsController.$inject = [
    'abp.services.finance.commonLookup',
    'abp.services.finance.malaysiaTaxInformation',
    'abp.services.app.malaysiaTaxInformation',
    '$scope'
  ];

  function EditIrbmMyInvoisSettingsController(
    commonLookupSvc,
    financeMalaysiaTaxInformationSvc,
    portalMalaysiaTaxInformationSvc,
    $scope
  ) {
    const vm = this;
    vm.loading = 0;

    vm.validateForm = validateForm;
    vm.getPayload = getPayload;
    vm.save = validateForm;
    vm.$onInit = init;

    function init() {
      vm.container.addWidget(this);
      var watch = $scope.$watch('vm.data', function(newValue, oldValue) {
        if (newValue) {
          getSettings();
          watch(); // deregister the watch
        }
      }, true); // watch for changes to vm.data
    }

    function getSettings() {
      vm.loading += 1;
      commonLookupSvc
        .getCommonSettings()
        .success((data) => {
          vm.hasTaxInformationCollection = data.taxInformationCollection;
          if (vm.hasTaxInformationCollection) {
            let primarySubsidiaryTaxInfo = null;

            if (vm.data.isSubsidiaryBillAccountMode != null && !vm.data.isSubsidiaryBillAccountMode) 
              primarySubsidiaryTaxInfo = _.find(vm.data.subTenants, (subTenant) => subTenant.isPrimary);
            
            vm.taxInformations = _.cloneDeep(primarySubsidiaryTaxInfo
              ? [primarySubsidiaryTaxInfo]
              : vm.data.subTenants);
          }
        })
        .finally(() => {
          vm.loading -= 1;
        });
    }

    function getInput() {
      if (vm.data.isSubsidiaryBillAccountMode != null && !vm.data.isSubsidiaryBillAccountMode) {
        let primarySubsidiaryTaxInfo = vm.taxInformations[0];

        _.forEach(vm.data.subTenants, (subTenant) => {
          subTenant.taxIdentificationNumber = primarySubsidiaryTaxInfo.taxIdentificationNumber;
          subTenant.businessRegistrationNumber = primarySubsidiaryTaxInfo.businessRegistrationNumber;
          subTenant.sstRegistrationNumber = primarySubsidiaryTaxInfo.sstRegistrationNumber;
          subTenant.supportContactName = primarySubsidiaryTaxInfo.supportContactName;
          subTenant.supportContactEmail = primarySubsidiaryTaxInfo.supportContactEmail;
          subTenant.supportContactNumber = primarySubsidiaryTaxInfo.supportContactNumber;
        })

        return vm.data.subTenants;
      }

      return _.filter(vm.taxInformations, (d) => 
      d.taxIdentificationNumber !== null && 
      d.taxIdentificationNumber !== undefined && 
      d.taxIdentificationNumber !== '');
    }

    function getPayload(settings) {
      settings.malaysiaTaxInformations.subTenants = getInput();
    }

    function validateForm() {
      const input = getInput();

      if (!vm.hasTaxInformationCollection || vm.countryCode !== 'MY' || input.length === 0)
        return true;
      
      const { irbmMyInvoisSettingsEditForm } = $scope.vm;
      if (irbmMyInvoisSettingsEditForm.$invalid) {
        abp.notify.error(App.localize('TaxInformationIncorrect'));
        return true;
      }

      vm.loading += 1;
      financeMalaysiaTaxInformationSvc
        .bulkValidateTin({
          taxInformations: input
        })
        .success((message) => {
          if (message.output)
            abp.message.error(message.output);
          else {
            portalMalaysiaTaxInformationSvc
              .createUpdateTaxInformation({ subTenants: input })
              .success(() => {
                abp.notify.info(App.localize('TaxInformationSuccessfullySaved'));
              })
          }
        }).finally(() => {
          vm.loading -= 1;
        })
    }
  }
})();
